<template>
  <list-container-widget
    id="quizlet"
    :title="widgetName"
    :app-id="105"
    stretch
    class="d-flex"
  >
    <iframe v-if="quizletSrc" :src="quizletSrc" style="border:none;height: 100%; border-radius: 5px;" />
    <div v-else class="horizontal-placeholder d-flex flex-column flex-wrap justify-content-center align-items-center">
      <img :src="placeholder">
      <p class="text-primary">
        {{ $t('backoffice.feedback.placeholder-text', { item: $t('integrations.quizlet-title') }) }}
      </p>
    </div>
  </list-container-widget>
</template>

<script>
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';

export default {
  name: 'Quizlet',
  components: { ListContainerWidget },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  computed: {
    quizletSrc() {
      if (this.item?.customizations != null) {
        // There may be a link or code or both, we select the first one that comes in the api
        return this.quizletSetting[Object.keys(this.item?.customizations)[0]];
      }
      return null;
    },
    quizletSetting() {
      return {
        'Quizlet-link': `${this.item?.customizations['Quizlet-link']}/${this.model}/embed?x=1jj1"`,
        'Quizlet-code': `https://quizlet.com/${this.item?.customizations['Quizlet-code']}/${this.model}/embed?x=1jj1"`,
      };
    },
    item() {
      return this.$store.getters.item('integrations', 'quizlet');
    },
    model() {
      return this.item?.customizations['Quizlet-mode-select'].value;
    },
    placeholder() {
      return Placeholder;
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'addons',
        customName: 'integrations',
        page: 1,
        requestConfig: {
          orderByDate: -1,
          type: 'integrations',
        },
      });
      this.isLoading = false;
    },
  },
};
</script>
